import { selectorFamily } from "recoil";

import { getClient } from "./client";
import { R4 } from "@iguhealth/fhir-types/versions";

export const getQuestionnaire = selectorFamily({
  key: "expansion",
  get:
    (url: string) =>
    async ({ get }) => {
      const client = get(getClient);
      const questionnaire = client.search_type({}, R4, "Questionnaire", [
        { name: "url", value: [url] },
      ]);
      return questionnaire;
    },
});
